// Page transitions are done with react-transition-group

import { RouterScrollProvider } from '@moxy/next-router-scroll';
import { gsap } from 'gsap/dist/gsap';
import Cookies from 'js-cookie';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Analytics } from 'components/analytics/Analytics';
import { AppInner } from 'components/general/appInner/AppInner';
import { IntroAnimation } from 'components/introAnimation/IntroAnimation';
import { MainNav } from 'components/mainNav/MainNav';
import { PreviewBanner } from 'components/previewBanner/PreviewBanner';
import { ScreenSaver } from 'components/screenSaver/ScreenSaver';
import { SelectorMenu } from 'components/selectorMenu/SelectorMenu';
import { AppContext } from 'context/AppContext';
import { ThemeContext, ThemeType } from 'context/ThemeContext';
import {
  GlobalContentContext,
  GlobalContentContextType,
  SharedDataType,
} from 'context/GlobalContentContext';
import { useWindowSize } from 'hooks/useWindowSize';
import { isBrowser, setCSSVar } from 'utils/helpers';

import '../scss/global.scss';
import style from './_app.module.scss';

export const DESKTOP_BREAKPOINT = `(min-width: 768px)`;

function MyApp(props) {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>([]);
  const [theme, setTheme] = useState<ThemeType>('light');
  const [showScreenSaverAnimation, setShowScreenSaverAnimation] = useState<boolean>(false);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<string | undefined>(
    Cookies.get('cookiesTD'),
  );
  const [globalData, setGlobalData] = useState<SharedDataType>({
    intro: { html: '' },
    sidebar: { title: '' },
    projects: [],
    offices: [],
    socials: {},
    analytics: {head: '', body: ''},
  });

  const size = useWindowSize();
  const delayedScreenSaverCallRef = useRef<any>();
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [showCarouselIntro, setShowCarouselIntro] = useState<boolean>(false);
  const is404Page = router.pathname === '/404';
  const handleRouteChange = (url) => {
    document.body.classList.remove('js-no-mobile-sticky-menu');
    history.push(url);
    try {
      // @ts-ignore
      window.ga('send', 'pageview', url);
    } catch (error) {
      console.error(error);
    }
  };
  const onShowIntroComplete = (triggerCarousel: boolean) => {
    setShowIntro(false);
  };
  const startCarouselScroll = (triggerCarousel: boolean) => {
    setShowCarouselIntro(triggerCarousel);
  };

  const onSetTheme = (theme: ThemeType) => {
    setTheme(theme);
  };

  const themeValues = { theme, setTheme: onSetTheme };
  const globalvalues: GlobalContentContextType = {
    data: globalData,
    setData: setGlobalData,
  };

  const showScreenSaver = () => {
    if (!showIntro && !showScreenSaverAnimation) {
      setShowScreenSaverAnimation(true);
    }
  };

  const resetScreenSaverTimer = () => {
    if (!isBrowser) return;
    if (showScreenSaverAnimation) {
      setShowScreenSaverAnimation(false);
    }

    delayedScreenSaverCallRef.current && delayedScreenSaverCallRef.current.kill();
    gsap.killTweensOf(showScreenSaver);

    delayedScreenSaverCallRef.current = gsap.delayedCall(60, showScreenSaver);
  };

  const onMouseMove = () => {
    resetScreenSaverTimer();
  };

  const onKey = (e) => {
    if (e.key === 'r') {
      showScreenSaverAnimation ? resetScreenSaverTimer() : showScreenSaver();
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchstart', onMouseMove);
    document.addEventListener('scroll', onMouseMove);
    document.addEventListener('keydown', onKey);
    window.addEventListener('wheel', onMouseMove);

    return () => {
      document.removeEventListener('keydown', onKey);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('scroll', onMouseMove);
      document.removeEventListener('touchstart', onMouseMove);
      window.removeEventListener('wheel', onMouseMove);
    };
  }, [showIntro, showScreenSaverAnimation]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add('is-fonts-loaded');
    });

    history.push(router.asPath);

    // reset the postion on refresh
    window.scrollTo(0, 0);
    // don't pass anything into dep array, it only needs to run once
  }, []);

  const onResize = () => {
    setCSSVar('--vh', `${window.innerHeight}px`);
    setCSSVar('--vw', `${document.body.clientWidth}px`);
  };

  useEffect(() => {
    onResize();
  }, [size]);

  useEffect(() => {
    if (showIntro) {
      document.body.classList.add('is-scroll-disabled');
    } else {
      document.body.classList.remove('is-scroll-disabled');
    }
  }, [showIntro]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // https://github.com/vercel/next.js/issues/17464#issuecomment-914561683
    if (router.pathname === '/404') {
      router.push(router.pathname);
    } else {
      router.push(router.asPath);
    }
  }, []);

  const routeChange = () => {
    // Temporary fix to avoid flash of unstyled content
    // during route transitions. Keep an eye on this
    // issue and remove this code when resolved:
    // https://github.com/vercel/next.js/issues/17464

    const tempFix = () => {
      const allStyleElems = document.querySelectorAll('style[media="x"]');
      allStyleElems.forEach((elem) => {
        elem.removeAttribute('media');
      });
    };
    tempFix();
  };
  const onCookieAccept = () => {
    Cookies.set('cookiesTD', 'accepted', { expires: 21 });
    setHasAcceptedCookies('accepted');
  };
  const appContextValues = {
    resetScreenSaverTimer: () => {
      resetScreenSaverTimer();
    },
  };

  Router.events.on('routeChangeComplete', routeChange);
  Router.events.on('routeChangeStart', routeChange);

  return (
    <ThemeContext.Provider value={themeValues}>
      <div className={theme}>
        <Head>
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_HOST}${router.asPath}`}
            key="url"
          />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
          <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: dark)" />
          <meta name="theme-color" content="#ffffff" />
          <link rel="manifest" href="/site.webmanifest" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="viewport" content="initial-scale=1, width=device-width" />

          <link
            rel="preload"
            href="/fonts/founders-grotesk-light.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/founders-grotesk-light-italic.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/FoundersGroteskWeb-Semibold.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/FoundersGroteskWeb-SemiboldItalic.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/founders-grotesk-x-condensed-semibold.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/founders-grotesk-mono-light.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/GT-Alpina-Fine-Condensed-Light.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          <link
            rel="preload"
            href="/fonts/GT-Alpina-Fine-Condensed-Light-Italic.woff2"
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
          
          {/* eslint-disable-next-line @next/next/no-sync-scripts */}
          <script
            key="cookielawscript"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="0eaca0f3-b933-440a-9de4-2fd00988c5eb"
          />
        </Head>
        <Analytics html={globalData.analytics.head} />
        <Analytics html={globalData.analytics.body} />

        <GlobalContentContext.Provider value={globalvalues}>
          {showIntro && (
            <IntroAnimation startScroll={startCarouselScroll} onComplete={onShowIntroComplete} />
          )}
          {<ScreenSaver active={!showIntro && !is404Page} show={showScreenSaverAnimation} />}
          <MainNav />
          <div className={`${style.fadeOverlay} js-transitionOverlay`}></div>
          <div className={`${style.selectorMenuContainer} `}>
            <SelectorMenu showIntroAnimation={showCarouselIntro} projects={globalData.projects} />
          </div>
          <div>{props.router.isPreview && <PreviewBanner />}</div>
          <div className={style.pageContainer}>
            <div className={style.page}>
              <div className={style.appInner}>
                <RouterScrollProvider>
                  <AppContext.Provider value={appContextValues}>
                    <AppInner {...props} history={history} />
                  </AppContext.Provider>
                </RouterScrollProvider>
              </div>
            </div>
          </div>
        </GlobalContentContext.Provider>
      </div>
    </ThemeContext.Provider>
  );
}

export default MyApp;
